import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your translations
const resources = {
    en: {
        translation: {
            navigation: {
                home: "Home",
                about: "About",
                services: "Services",
                blogs: "Blog",
                contact: "Contact"
            },
            home: {
                main_heading: "Elevate Your MENA Real Estate Experience",
                sub_heading: "Empower your Real Estate Journey with Innovation and Clarity through AI",
                touch_btn: "Get In Touch",
                call_btn: "Call Now"
            },
            about: {
                main_heading: "About Us",
                text: "We are MENA Proptech, committed to delivering exceptional service and expertise in every real estate transaction. With a dedicated team of professionals, we prioritize your needs and goals, ensuring a seamless and rewarding experience. Whether you're buying, selling, or investing, trust us to guide you through the process with integrity and excellence."
            },
            services: {
                main_heading: "Our Services",
                sub_heading: "We Support You Throughout Your Real Estate Journey",
                b2b_btn: "B2B",
                b2c_btn: "B2C",
                fortify: {
                    heading: "Real Estate Management Software",
                    powered_by: "powered by",
                    text: "Offer comprehensive software solutions tailored for real estate agencies, including property management, listings management, and CRM tools.",
                    btn: "Explore Fortify"
                },
                vr: {
                    heading: "Virtual Tours and 3D Visualization",
                    powered_by: "powered by",
                    text: "Showcase advanced visualization tools for property tours and interactive 3D floor plans.",
                    btn: "Explore More"
                },
                datastate: {
                    heading: "Data Analytics and Feasibility Studies",
                    powered_by: "powered by",
                    text: "Provide data-driven insights and analytics to optimize marketing strategies, pricing decisions, and property investments.",
                    btn: "Explore DataState.ai"
                },
                neura: {
                    heading: "AI-Powered Real Estate Tools",
                    powered_by: "powered by",
                    text: "Seamless interactions with voice commands, appointment scheduling, and automated customer support.",
                    btn: "Explore More",
                },
                developer: {
                    heading: "Developer Sector Solutions",
                    powered_by: "powered by",
                    text: "Customized software development services for developers, such as project management software, collaboration tools, and data visualization platforms.",
                    btn: "Explore More"
                },
                listing: {
                    heading: "Property Listing Platform",
                    powered_by: "powered by",
                    text: "We develop platforms designed for seamless property listing, enabling real estate agents to efficiently manage their portfolios.",
                    btn: "Explore More"
                }
            },
            blogs: {
                sub_heading: "Insights To Empower You",
                main_heading: "Read Our Blogs",
                blog_1: {
                    heading: "Digital Transformation In MENA Real Estate Landscape",
                    text: "As digital transformation reshapes industries globally, the real estate sector in the MENA region stands at a pivotal juncture.",
                    btn: "Read More"
                },
                blog_2: {
                    heading: "International Real Estate Partnerships Drive Innovation",
                    text: "A recent strategic partnership between UAE’s Reportage Properties and Lahore’s Empire Holding Pakistan. MENA Proptech underscores how international collaboration is setting new benchmarks in the property market.",
                    btn: "Read More"
                },
                blog_3: {
                    heading: "UAE-Pakistan Economic Relations",
                    text: " Mr. Bakheet Ateeq Al-Rumeithi, the Consul General of UAE, shared his vision on the burgeoning economic ties between the UAE and Pakistan.",
                    btn: "Read More"
                }
            },
            contact: {
                sub_heading: "Ready to Supercharge Your Real Estate Efforts?",
                main_heading: "Get In Touch Today!",
                text: "Have questions, concerns, or ready to start your real estate journey? Reach out to us today for personalized assistance and expert guidance.",
            },
            footer: {
                text: "We are MENA Proptech, committed to delivering exceptional service and expertise in every real estate transaction. With a dedicated team of professionals, we prioritize your needs and goals, ensuring a seamless and rewarding experience. Whether you're buying, selling, or investing, trust us to guide you through the process with integrity and excellence.",
                contact_heading: "Contact Us",
                social_heading: "Let's Get Social"
            },

        },
    },
    ar: {
        translation: {
            navigation: {
                home: "الرئيسية",
                about: "نبذة عنا",
                services: "الخدمات",
                blogs: "المدونات",
                contact: "تواصل معنا"
            },
            home: {
                main_heading: "رفع تجربتك العقارية في منطقة الشرق الأوسط وشمال أفريقيا",
                sub_heading: "قم بتعزيز رحلتك العقارية بالابتكار والوضوح من خلال الذكاء الاصطناعي",
                touch_btn: "تواصل معنا",
                call_btn: "اتصل الآن"
            },
            about: {
                main_heading: "من نحن",
                text: "نحن MENA Proptech، ملتزمون بتقديم خدمة استثنائية وخبرة متميزة في كل معاملة عقارية. مع فريق متخصص من المحترفين، نضع احتياجاتك وأهدافك في المقام الأول، لضمان تجربة سلسة ومثمرة. سواء كنت تشتري أو تبيع أو تستثمر، ثق بنا لنرشدك خلال العملية بنزاهة وتميز."
            },
            services: {
                main_heading: "خدماتنا",
                sub_heading: "ندعمك طوال رحلتك في مجال العقارات",
                b2b_btn: "الأعمال التجارية بين الشركات",
                b2c_btn: "الأعمال التجارية بين الشركات والمستهلكين",
                fortify: {
                    heading: "برنامج إدارة العقارات",
                    powered_by: "مدعوم من",
                    text: "نقدم حلولاً برمجية شاملة مصممة خصيصًا لوكالات العقارات، بما في ذلك إدارة العقارات، وإدارة القوائم، وأدوات إدارة علاقات العملاء.",
                    btn: "استكشاف فورتيفاي"
                },
                vr: {
                    heading: "الجولات الافتراضية والتصور ثلاثي الأبعاد",
                    powered_by: "مدعوم من",
                    text: "استعرض أدوات التصور المتقدمة للجولات العقارية والتخطيطات التفاعلية ثلاثية الأبعاد.",
                    btn: "استكشاف المزيد"
                },
                datastate: {
                    heading: "تحليلات البيانات ودراسات الجدوى",
                    powered_by: "مدعوم من",
                    text: "نقدم رؤى وتحليلات مبنية على البيانات لتحسين استراتيجيات التسويق وقرارات التسعير واستثمارات العقارات.",
                    btn: "استكشاف DataState.ai"
                },
                developer: {
                    heading: "حلول قطاع المطورين",
                    powered_by: "مدعوم من",
                    text: "خدمات تطوير برمجيات مخصصة للمطورين، مثل برامج إدارة المشاريع وأدوات التعاون ومنصات تصور البيانات.",
                    btn: "استكشاف المزيد"
                },
                listing: {
                    heading: "منصة عرض العقارات",
                    powered_by: "مدعوم من",
                    text: "نطور منصات مصممة لعرض العقارات بشكل سلس، مما يمكّن وكلاء العقارات من إدارة محافظهم بكفاءة.",
                    btn: "استكشاف المزيد"
                }
            },
            blogs: {
                sub_heading: "رؤى لتعزيز قدراتك",
                main_heading: "اقرأ مدوناتنا",
                blog_1: {
                    heading: "التحول الرقمي في مشهد العقارات في منطقة الشرق الأوسط وشمال أفريقيا",
                    text: "بينما يعيد التحول الرقمي تشكيل الصناعات على مستوى العالم، يقف قطاع العقارات في منطقة الشرق الأوسط وشمال أفريقيا عند نقطة تحول حاسمة.",
                    btn: "اقرأ المزيد"
                },
                blog_2: {
                    heading: "الشراكات العقارية الدولية تدفع عجلة الابتكار",
                    text: "شراكة استراتيجية حديثة بين شركة ريبورتاج العقارية في الإمارات العربية المتحدة وإمباير هولدينغز في باكستان. تؤكد MENA Proptech كيف أن التعاون الدولي يضع معايير جديدة في سوق العقارات.",
                    btn: "اقرأ المزيد"
                },
                blog_3: {
                    heading: "العلاقات الاقتصادية بين الإمارات وباكستان",
                    text: "شارك السيد بخيت عتيق الرميثي، القنصل العام لدولة الإمارات، رؤيته حول العلاقات الاقتصادية المزدهرة بين الإمارات وباكستان.",
                    btn: "اقرأ المزيد"
                }
            },
            contact: {
                sub_heading: "هل أنت مستعد لتعزيز جهودك العقارية؟",
                main_heading: "تواصل معنا اليوم!",
                text: "هل لديك أسئلة أو استفسارات، أم أنك مستعد لبدء رحلتك في مجال العقارات؟ تواصل معنا اليوم للحصول على مساعدة شخصية وإرشادات خبراء."
            },
            footer: {
                text: "نحن MENA Proptech، ملتزمون بتقديم خدمة استثنائية وخبرة في كل معاملة عقارية. مع فريق متخصص من المحترفين، نولي اهتمامًا لاحتياجاتك وأهدافك، لضمان تجربة سلسة ومجزية. سواء كنت تشتري أو تبيع أو تستثمر، يمكنك الاعتماد علينا لإرشادك خلال العملية بنزاهة وامتياز.",
                contact_heading: "تواصل معنا",
                social_heading: "لنبقى على تواصل"
            }
        },
    },
};

i18n
    .use(initReactI18next) // Passes i18n down to react-i18next
    .init({
        resources,
        lng: 'en', // Default language
        fallbackLng: 'en', // Fallback language
        interpolation: {
            escapeValue: false, // React already escapes by default
        },
    });

export default i18n;
